<template >
  <div class="con" v-if="!$store.state.pcshow">
    <img src="../../assets/img/structure/img(2).png" alt="" />
    <div class="btngroup">
      <router-link to="/companyIntro" :class="$route.path === '/companyIntro'? 'active': ''">
        <div class="companyIntro">
          <p>集团简介</p>
        </div>
      </router-link>
      <router-link to="/structure"  :class="$route.path === '/structure'? 'active': ''">
        <div class="structure">组织架构</div>
      </router-link>
    </div>
    <div class="companyIntro10">
      <img src="../../assets/img/index/companyIntro10.png" alt="" />
    </div>
  </div>
  <!-- 组织架构 -->
  <div class="main" v-else>
    <!-- 横幅图 -->
    <img src="../../assets/img/structure/img(2).png" alt="" />
    <div class="mainContent">
      <!-- 组织架构图 -->
      <div class="structContent">
        <!-- 左侧导航 -->
        <div class="navLeft">
          <div class="comeTop">
            <div class="comeYiLian">
              <h2>走进亿联体</h2>
              <h4>About ENITED</h4>
              <div class="img">
                <img src="../../assets/img/structure/img(3).png" alt="" />
              </div>
            </div>
            <div class="blankArea"></div>
          </div>
          <div class="comeBottom">
            <ul>
              <li>
                <div class="borderLeft"></div>
                <router-link to="/companyIntro">
                  <div class="contentRight">集团简介</div>
                </router-link>
              </li>
              <li
                :class="
                  $route.path === '/structure'
                    ? 'currentActive'
                    : 'defaultActive'
                "
              >
                <div class="borderLeft"></div>
                <router-link to="/companyIntro">
                  <div class="contentRight">
                    <p>组织架构</p>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <!-- 右边架构 -->
        <div class="navRight">
          <div class="titleTop">
            <h4>组织架构</h4>
          </div>
          <div class="structureMap">
            <img src="../../assets/img/structure/img(1).jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="stylus" >
  @media screen and (max-width: 960px) {
    .con{
      width: 100%;
      background: #F5F5F5;
      
      >img{
        display: block
        width:7.5rem;
        height:3rem;
      }

      .btngroup{
        width: 7.5rem;
        height: 1.3rem;
        padding-left:0.35rem;

        a{
          display: block;
          width: 1.68rem;
          height: 0.68rem;
          background: #FFFFFF;
          float: left
          margin-top: 0.3rem;
          margin-right: 0.42rem;
          font-size: 0.26rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 0.68rem;
          text-align: center; 
        }
        a.active{
          background: #0085D0;
          color: #ffffff;
        }
      }
      .companyIntro10{
        width: 7.5rem;
        height: 4.82rem;
        background: #FFFFFF;
        border-bottom:0.55rem solid #F5F5F5;

        img{
          display: block;
          width: 7.5rem;
          height: 3.93rem;
          padding:0.39rem 0 0.5rem 0;
        }
      }
    }
  }
  @media screen and (min-width: 960px) { 
    .main {
      font-family: Source Han Sans CN;
      color: #333333;
      margin-top: 100px;

      >img {
        width: 100%;
        height: 100%;
      }

      .mainContent {
        width: 1280px;
        margin: 0 auto;

        .structContent {
          margin: 40px 0 120px;
          display: flex;
          justify-content: space-between;

          .navLeft {
            .comeTop {
              .comeYiLian {
                width: 280px;
                height: 118px;
                background: #0085D0;
                position: relative;

                h2 {
                  height: 29px;
                  font-size: 30px;
                  color: #FFFFFF;
                  font-weight: bold;
                  line-height: 39px;
                  padding: 33px 0 0 43px;
                }

                h4 {
                  height: 14px;
                  font-size: 16px;
                  font-weight: bold;
                  color: #FFFEFE;
                  line-height: 39px;
                  opacity: 0.5;
                  margin: 10px 0 0 43px;
                }

                .img {
                  position: absolute;
                  left: 188px;
                  top: 35px;
                  width: 90px;
                  height: 76px;
                }
              }

              .blankArea {
                width: 280px;
                height: 24px;
                background: #F5F5F5;
                border-bottom: 1px solid #0085D0;
              }
            }

            .comeBottom {
              ul {
                li {
                  height: 61px;
                  display: flex;
                  align-items: center;
                  padding: 0 0 0 40px;
                  border-bottom: 1px solid #0085D0;

                  .borderLeft {
                    width: 5px;
                    height: 15px;
                    background: #0085D0;
                  }

                  .contentRight {
                    margin-left: 14px;
                    width: 64px;
                    font-size: 16px;
                    font-weight: 500;
                  }
                }

                .currentActive {
                  background-color: #0085D0;

                  p {
                    color: #fff;
                  }

                  .borderLeft {
                    background-color: #fff;
                  }
                }
              }
            }
          }

          .navRight {
            .titleTop {
              margin-bottom: 66px;
              border-bottom: 1px solid #EAEAEA;

              h4 {
                height: 44px;
                font-size: 26px;
                font-weight: 500;
                color: #0085D0;
                line-height: 39px;
              }
            }
          }
        }
      }
    }
  }
</style>